import React, { useContext } from 'react';
import { AuthContext } from './Auth';
import { Outlet } from "react-router-dom";
import LoginPage from "./LoginPage";


const ProtectedRoutes = () => {
    const { currentUser } = useContext(AuthContext);
    const isAuth = currentUser !== null;
    return isAuth ? <Outlet /> : <LoginPage />;
};

export default ProtectedRoutes;