import React, { useContext } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import LoginPage from './LoginPage';
import LogoutPage from './LogoutPage';
import Main from './Main';
import { AuthContext } from './Auth';
import ProtectedRoutes from './ProtectedRoutes';
import { getAuth } from "firebase/auth";

function App() {

  const { currentUser } = useContext(AuthContext);
  const auth = getAuth();

  return (
    <Router>
      <div className="App">
        <Routes>
          <Route path="/login" element={<LoginPage />} />
          <Route path="/logout" element={<LogoutPage />} />
          <Route element={<ProtectedRoutes />}>
            <Route path="/" element={
              <main className="App-main">
                <a href="/main">  
                <img src={`${process.env.PUBLIC_URL}/android-chrome-512x512.png`} className="App-logo" alt="logo" />
                </a>
                <h1>CAM-SNAPS</h1>
              </main>
            } />
            <Route path="/main" element={<Main auth={auth} />} />
          </Route>
        </Routes>
        <footer className="App-footer">
          {currentUser === null && <a href="/login">Log In</a>}
          {currentUser !== null && <a href="/logout">Log out</a>}
        </footer>
      </div>
    </Router>
  );
}

export default App;
