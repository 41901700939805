// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries
import React from 'react';
import 'firebase/auth';
import { initializeApp } from "firebase/app";
import { getAuth, GoogleAuthProvider, signInWithPopup } from "firebase/auth";
import './index.css';

const firebaseConfig = {
  apiKey: "AIzaSyAR9EtmhhYlST8vDCPcXT3V3yrDuJ2JWUs",
  authDomain: "cam-snap.firebaseapp.com",
  projectId: "cam-snap",
  storageBucket: "cam-snap.appspot.com",
  messagingSenderId: "97063272436",
  appId: "1:97063272436:web:7f8fc249cfc83327af83a1"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth();
const provider = new GoogleAuthProvider(app);

function LoginPage() {

  const handleLogin = () => {
    signInWithPopup(auth, provider)
      .then((result) => {
        window.location.href = "/main";
      }).catch((error) => {
        console.error(error);
      });
  }

  return (
    <div>
      <main className="App-main">
        <button onClick={handleLogin}>Login with Google</button>
        <img src={`${process.env.PUBLIC_URL}/android-chrome-512x512.png`} className="App-logo" alt="logo" />
        <h1 className='header-one'>CAM-SNAPS-LOGIN</h1>
      </main>
    </div>
  );
}

export default LoginPage;

