import React, { useState, useEffect, useCallback, useRef } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry"
import { FaTimes, FaArrowLeft, FaArrowRight, FaDownload, FaTrash } from 'react-icons/fa';

const Main = () => {
  const [images, setImages] = useState([]);
  const [hasMore, setHasMore] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);
  const lastImageRef = useRef(null);
  const rootUrl = process.env.REACT_APP_ROOT_URL;

  const getImages = useCallback(() => {

    let beforeTicks = 9223372036854775807;

    if (lastImageRef.current !== null) {
      beforeTicks = lastImageRef.current.ticks;
    }

    fetch(`${rootUrl}/${beforeTicks}`)
      .then(response => response.json())
      .then(data => {
        if (data.length === 0) {
          setHasMore(false);
        } else {
          lastImageRef.current = data[data.length - 1];
          setImages(prevImages => {
            const newImages = data.filter(newImage => !prevImages.some(prevImage => prevImage.ticks === newImage.ticks));
            return [...prevImages, ...newImages];
          });
        }
      }).catch(error => {
        console.error("Error during fetch:=", error);
      })

  }, [rootUrl]);

  useEffect(() => {
    getImages();
  }, [getImages]);

  const handleImageClick = (img, index) => {
    setCurrentIndex(index);
    setIsModalOpen(true);
  }

  const handleNext = () => {
    setCurrentIndex(prevIndex => (prevIndex + 1) % images.length);
  }

  const handlePrev = () => {
    setCurrentIndex(prevIndex => (prevIndex - 1 + images.length) % images.length);
  }

  const handleDelete = async () => {

    const imageToDelete = images[currentIndex];

    const fileName = imageToDelete.fileName.startsWith('\\')
      ? imageToDelete.fileName.slice(1)
      : imageToDelete.fileName;

    const url = `${rootUrl}/${encodeURIComponent(fileName)}`;

    try {
      await fetch(url, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json'
        },
      }).then(() => {
        const newImages = [...images];
        newImages.splice(currentIndex, 1);
        setImages(newImages);
        if (newImages.length > 0) {
          setCurrentIndex(prevIndex => {
            if (prevIndex >= newImages.length) {
              return newImages.length - 1;
            }
            return prevIndex;
          });
        }
      });
    } catch (error) {
      console.error('Error while deleteing:=', error);
    }

  };

  const handleDownload = () => {
    const imageToDownload = images[currentIndex];

    // Creating an anchor element to download the image
    const link = document.createElement('a');
    link.href = imageToDownload.imageBase64;
    link.download = imageToDownload.fileName;

    // This will start the download
    document.body.appendChild(link);
    link.click();

    // Clean up to ensure no unnecessary elements remain
    document.body.removeChild(link);
  };


  return (
    <main id="scrollableMain" className="App-main-scrollable">
      <InfiniteScroll
        dataLength={images.length}
        next={getImages}
        hasMore={hasMore}
        loader={<h4>Loading...</h4>}
        endMessage={
          <p style={{ textAlign: 'center' }}>
            <b>Yup, that's all the ammo we got.</b>
          </p>
        }
        scrollableTarget="scrollableMain"
        id="scrollableDiv"
      >
        <ResponsiveMasonry columnsCountBreakPoints={{ 150: 1, 300: 2, 450: 3, 600: 4, 750: 5, 900: 6, 1050: 7 }}>
          <Masonry>
            {images.map((img, index) => (
              <img
                id={img.ticks}
                name={img.ticks}
                key={img.ticks}
                src={img.imageBase64}
                alt={img.fileName}
                onClick={() => handleImageClick(img, index)}
                style={{ marginTop: '10px', marginLeft: '10px', borderRadius: '10px' }} />
            ))}
          </Masonry>
        </ResponsiveMasonry>
      </InfiniteScroll>

      {isModalOpen && (
        <div className="modal">
          <div className="modal-content">
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <div>
                <button onClick={handlePrev}>
                  <FaArrowLeft />
                </button>
                <button onClick={handleNext}>
                  <FaArrowRight />
                </button>
              </div>
              <div>
                <button onClick={handleDelete}>
                  <FaTrash />
                </button>
                <button onClick={handleDownload}>
                  <FaDownload />
                </button>
                <button onClick={() => setIsModalOpen(false)}>
                  <FaTimes />
                </button>
              </div>
            </div>
            <img src={images[currentIndex].imageBase64} alt={images[currentIndex].fileName} />
          </div>
        </div>
      )}

    </main>
  );
}

export default Main;
