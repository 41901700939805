import React from 'react';
import { signOut, getAuth } from "firebase/auth";

const auth = getAuth();

function LogoutPage() {

  function handleLogout(event) {
    event.preventDefault();
    signOut(auth).then(() => {
      console.log("Signed out!");
      window.location.href = "/";
    }).catch((error) => {
      console.error("Error signing out. Error:=", error);
    });
  }
  return (
    <div>
      <main className="App-main">
        <button onClick={handleLogout}>Logout from Cam-Snaps</button>
        <img src={`${process.env.PUBLIC_URL}/android-chrome-512x512.png`} className="App-logo" alt="logo" />
        <h1 className='header-one'>CAM-SNAPS-LOGOUT</h1>
      </main>
    </div>

  );
}

export default LogoutPage;

